import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { MapperEntityService } from './mapper-entity.service';
@Injectable({ providedIn: 'root' })
export class MapperNamesResolver implements Resolve<string[]> {
  constructor(private entityService: MapperEntityService) {}

  resolve(): Observable<string[]> {
    return this.entityService.getMapperNames();
  }
}
