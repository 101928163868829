import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ConnectionMode, FileType } from '@mri-platform/import-export/common-state';
import { DefaultEntityService, EntityIdType, entityNumberIdParser, isEntityNew } from '@mri-platform/shared/entity';
import {
  ChangeSet,
  ChangeSetItem,
  changeSetItemFactory as cif,
  DefaultDataServiceConfig,
  MergeStrategy
} from '@ngrx/data';
import { saveAs } from 'file-saver';
import { lastValueFrom, Observable, of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { createMapper, Mapper, UploadMapper } from '../models';
import { platformMapperSelectors } from './selectors';

@Injectable({ providedIn: 'root' })
export class MapperEntityService extends DefaultEntityService<Mapper> {
  parseId = entityNumberIdParser;

  constructor(
    injector: Injector,
    private apiConfig: DefaultDataServiceConfig,
    private http: HttpClient
  ) {
    super(Mapper.entityName, injector);
  }

  entitiesByApiId$(id: EntityIdType, mode: ConnectionMode) {
    const selector =
      mode === ConnectionMode.Import
        ? platformMapperSelectors.selectEntitiesForDestinationConnectionId(id)
        : platformMapperSelectors.selectEntitiesForSourceConnectionId(id);

    return this.store.select(selector);
  }

  create(): Observable<Mapper> {
    return of(createMapper());
  }

  save(entity: Mapper) {
    if (isEntityNew(entity, this.selectId)) {
      return this.add(Mapper.omitId(entity));
    } else {
      return this.update(entity);
    }
  }

  deleteMappers(mappers: Mapper[]) {
    const ids = [...mappers.map(mapper => mapper.id)];
    const changes: ChangeSetItem[] = [cif.delete(Mapper.entityName, ids)];
    const api = `${this.apiConfig.root}/mappers/changeset`;
    const changeSet: ChangeSet = { changes, tag: 'Delete mappers' };
    return this.entityDispatcher.saveEntities(changeSet, api);
  }

  async downloadMapper(entity: Mapper) {
    const api = `${this.apiConfig.root}/mappers/${entity.id}/download`;
    const blob = await lastValueFrom(this.http.get(api, { responseType: 'blob' }));
    saveAs(blob, `${entity.name}.${FileType.Json}`);
  }

  uploadMapper(data: UploadMapper): Observable<Mapper> {
    const formData = new FormData();
    formData.append('PlatformId', `${data.platformId}`);
    formData.append('DataSourceId', `${data.datasourceId}`);
    formData.append('File', data.file);
    const api = `${this.apiConfig.root}/mappers/upload`;
    return this.http.post<Mapper>(api, formData).pipe(
      tap(mapper => {
        this.addOneToCache(mapper, { mergeStrategy: MergeStrategy.IgnoreChanges });
      })
    );
  }

  getMapperNames() {
    this.setLoading(true);
    const url = `${this.apiConfig.root}/mappers/names`;
    return this.http.get<string[]>(url).pipe(finalize(() => this.setLoading(false)));
  }
}
